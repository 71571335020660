import oidc from 'oidc-client'

const { VUE_APP_BASE_URL: baseUrl } = process.env
const MICROSOFT_LOGIN = 'https://login.microsoftonline.com'

export default {
  setup ({ azuread, google } = {}) {
    const settings = {
      azuread: {
        userStore: new oidc.WebStorageStateStore(),
        authority: `${MICROSOFT_LOGIN}/${azuread?.tenant_id}/v2.0`,
        client_id: azuread?.client_id,
        redirect_uri: `${baseUrl}/oidc-callback`,
        popup_redirect_uri: `${baseUrl}/oidc-popup-callback`,
        response_type: 'id_token',
        scope: 'openid profile email',
        metadata: {
          issuer: `${MICROSOFT_LOGIN}/${azuread?.tenant_id}/v2.0`,
          jwks_uri: `${MICROSOFT_LOGIN}/${azuread?.tenant_id}/discovery/v2.0/keys`,
          end_session_endpoint: `${MICROSOFT_LOGIN}/${azuread?.tenant_id}/oauth2/v2.0/logout`,
          authorization_endpoint: `${MICROSOFT_LOGIN}/${azuread?.tenant_id}/oauth2/v2.0/authorize`,
          token_endpoint: `${MICROSOFT_LOGIN}/${azuread?.tenant_id}/oauth2/v2.0/token`,
        },
      },
      google: {
        userStore: new oidc.WebStorageStateStore(),
        authority: 'https://accounts.google.com/',
        client_id: google?.client_id,
        redirect_uri: `${baseUrl}/oidc-callback`,
        popup_redirect_uri: `${baseUrl}/oidc-popup-callback`,
        response_type: 'id_token',
        scope: 'openid profile email',
        state: 'state_parameter_passthrough_value',
      },
    }

    for (const [socialProvider, config] of _.entries(settings)) {
      this.providers[socialProvider] = new oidc.UserManager(config)
    }
  },
  providers: {},
}
